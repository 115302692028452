@mixin Info {
    #info-page {
        display: flex;
        flex-flow: column;
        p {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
        }
        em {
            font-style: italic;
        }
    }

    #info-page.landscape {
        width: 45vw;
    }

    #info-page.portrait {
        width: 70vw;
    }
}