@font-face{
    font-family: Framboisier;
    font-weight: regular; 
    src: url(fonts/framboisier/framboisiertrial-bold.woff) format('woff');
}

@font-face{
    font-family: Tekst A;
    font-style: italic;
    font-weight: bold;
    src: url(fonts/tekst/Tekst-ABoldItalicTRIAL.woff) format('woff'),;
}

@font-face{
    font-family: Tekst A;
    font-style: normal;
    font-weight: bold;
    src: url(fonts/tekst/Tekst-ABoldTRIAL.woff) format('woff'),;
}

@font-face{
    font-family: Tekst A;
    font-style: italic;
    font-weight: normal;
    src: url(fonts/tekst/Tekst-AItalicTRIAL.woff) format('woff'),;
}

@font-face{
    font-family: Tekst A;
    font-style: roman;
    font-weight: normal;
    src: url(fonts/tekst/Tekst-ARomanTRIAL.woff) format('woff'),;
}

@font-face{
    font-family: Tekst A;
    font-style: italic;
    font-weight: normal;
    src: url(fonts/tekst/Tekst-AItalicTRIAL.woff) format('woff'),;
}

@font-face{
    font-family: Tekst D;
    font-style: italic;
    font-weight: bold;
    src: url(fonts/tekst/Tekst-DBoldItalicTRIAL.woff) format('woff'),;
}

@font-face{
    font-family: Tekst D;
    font-style: normal;
    font-weight: bold;
    src: url(fonts/tekst/Tekst-DBoldTRIAL.woff) format('woff'),;
}

@font-face{
    font-family: Tekst D;
    font-style: italic;
    font-weight: normal;
    src: url(fonts/tekst/Tekst-DItalicTRIAL.woff) format('woff'),;
}

@font-face{
    font-family: Tekst D;
    font-style: roman;
    font-weight: normal;
    src: url(fonts/tekst/Tekst-DRomanTRIAL.woff) format('woff'),;
}

@font-face{
    font-family: Tekst M;
    font-style: normal;
    font-weight: bold;
    src: url(fonts/tekst/Tekst-MBoldTRIAL.woff) format('woff'),;
}

@font-face{
    font-family: Tekst M;
    font-style: roman;
    font-weight: normal;
    src: url(fonts/tekst/Tekst-MRomanTRIAL.woff) format('woff'),;
}