@mixin Diary {
    #diary-container {
        display: flex;
        flex-flow: column;
        width: 80vw; 
    }
    .diary-entry {
        display: flex; 
        flex-flow: column;
        margin: .5rem 0rem 0.5rem 0rem;
        h3 {
            margin: .25rem;
        }
        p {
            margin: 0.2rem;
        }
        .diary-entry-date {
            font-size: 0.8rem;
        }
        img {
            width: 60%;
            height: auto;
        }
    }
}