@use './features/01-home/home' as *;
@use './features/shared/text/text' as *;
@use './features/shared/pages' as *;
@use './reset.scss' as *;

.App {
  @include Pages;
  @include Reset;
  display: grid;
}

.App.landscape{
    grid-template-columns: 15vw auto;
}

.App.portrait{
    grid-template-columns: 20vw auto;
}

#sidebar-container{

  #sidebar-links{

    .active-link{
      color: rgba(0, 184, 31, 0.89);
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
}

#root{
  color: rgba(0, 2, 109, 0.89);
  font-family: Calibri,sans-serif;
  font-size: small;
}
