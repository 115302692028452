@mixin CV {
    #resume-container{
        height: 95vh;
        display: grid;
        align-items: center;
        grid-template-rows: 95% 5%;
        grid-template-columns: 50% 50%;
        grid-template-areas:
            "resume resume"
            "bottom-left bottom-right";
        .cv-pdf{
            grid-area: resume;
            z-index:0;
            height: 100%;
            width: auto;
        }
        #resume-pg-nav{
            z-index: 2;
            grid-area: bottom-right;
            justify-self: end;
            cursor: pointer;
        }
        #resume-dl-link{
            z-index:2;
            font-size:.8rem;
            grid-area: bottom-left;
        }
    }
}